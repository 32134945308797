export default {
  methods: {
    // 锚点导航
    scrollToAnchor (item) {
      this.$nextTick(() => {
        const element = document.querySelector('#section' + item.id)
        if (element) {
          // 平滑滚动到元素
          window.scrollTo({
            behavior: 'smooth',
            top: element.offsetTop - 80
          })
        }
        if (this.$store.state.isDrawer) {
          this.$store.commit('toggleDrawer', false)
        }
      })
    },
    // 页面收缩边距
    collapseMarginLeft(){
      return this.$store.state.isMobile?'0px': this.$store.state.collapse?'200px':'60px'
    }
  }
}
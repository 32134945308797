<template>
  <el-dialog :visible.sync="dialogVisible" :width="dialogWidth" append-to-body :show-close="false">
    <ul class="dialogUl">
      <li class="titleImg">
        <img src="@/assets/logo-collapsed.png" /><span>{{ siteName }}</span>
      </li>
      <li class="copywriting">{{ title }}</li>
      <li class="contentText">
        {{ content }}
      </li>
      <li class="buttonEl">
        <el-button round @click="determineClick2">否</el-button>
        <el-button type="primary" @click="determineClick" round style="background: rgb(35, 83, 243);">是</el-button>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  props: ['inquiryGuidance'],

  data() {
    return {
      siteName: localStorage.getItem('siteName'),
      dialogVisible: this.inquiryGuidance,
      dialogWidth: '1000px', // 默认宽度
      title: '',
      content: ''
    }
  },
  watch: {
    inquiryGuidance: {
      handler(val) {
        this.dialogVisible = val
      },
      deep: true
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    this.dialogVisible = false
  },
  methods: {
    handleResize() {
      if (!localStorage.getItem('keyTitle')) {
        localStorage.setItem('keyTitle', 'Android')
      }
      const dataHome =
        JSON.parse(localStorage.getItem('homeFrame')) && JSON.parse(localStorage.getItem('homeFrame')).length > 0
          ? JSON.parse(localStorage.getItem('homeFrame'))
          : []
      this.title = dataHome[0]
      this.content = dataHome[1]
      if (window.innerWidth > 1000) {
        this.dialogWidth = '700px' // 如果页面宽度大于1000，设置宽度为80%
      } else {
        this.dialogWidth = '90%' // 否则设置宽度为50%
      }
    },
    determineClick() {
      let keyTitle = 'Android'
      if (localStorage.getItem('keyTitle')) {
        keyTitle = localStorage.getItem('keyTitle') == 'Android' ? 'Ios' : 'Android'
      }
      this.dialogVisible = false
      localStorage.setItem('keyTitle', keyTitle)
      this.$emit('destruction')
      window.location.reload()
    },
    determineClick2() {
      this.dialogVisible = false
      this.$emit('destruction')
    }
  }
}
</script>

<style lang="less" scoped>
.dialogUl {
  li {
    list-style: none;
    text-align: center;
  }
  .titleImg {
    font-size: 25px;
    font-weight: bold;
    color: #000;
    line-height: 45px;
    img {
      width: 45px;
      margin: 0 10px;
    }
  }
  .copywriting {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    color: rgb(35, 83, 243);
  }
  .contentText {
    line-height: 25px;
    color: #333;
  }
  .buttonEl {
    margin-top: 20px;
  }
  .el-button {
    margin: 0 15px;
  }
  .el-button.is-round {
    padding: 12px 43px;
  }
}
</style>
<style>
.el-dialog__header {
  display: none;
}
</style>
